<template>
  <section id="register" class="register">
    <div class="row" style="margin-left: 0; margin-right: 0;">
      <div class="card-panel grey lighten-3">
        <h4 class="grey-text text-darken-2">{{ $t('auth.sign_up') }}</h4>
        <form id="register-form" role="form" @submit.prevent="registerUser">
          <div class="col s12 l3" style="margin-left:0; margin-right: 0">
            <label>{{ $t('auth.salutation')}}</label>
            <select class="browser-default" v-model="profile.gender">
              <option value="male" selected>{{ $t('auth.male')}}</option>
              <option value="female">{{ $t('auth.female')}}</option>
            </select>
          </div>
          <div class="input-field col s12 l4" style="margin-left:0; margin-right: 0">
            <input type="text" id="name" class="validate" v-model="name" required>
            <label for="name">{{ $t('auth.name') }}</label>
          </div>
          <div class="input-field col s12 l5">
            <input type="text" id="surname" class="validate" v-model="surname" required>
            <label for="surname">{{ $t('auth.surname') }}</label>
          </div>
          <div class="input-field col s12 m12">
            <input type="text" id="organization" v-model="profile.organization">
            <label for="organization">{{ $t('auth.organization')}}</label>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12">
              <input type="email" id="email" class="validate" v-model="email" required>
              <label for="email">Email</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 m4">
              <label for="phone">{{ $t('auth.phone') }}</label>
              <input type="text" onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="phone" class="validate" v-model="profile.phone" required>
            </div>
            <div class="input-field col s12 m4">
              <label for="mobile">{{ $t('auth.mobile') }}</label>
              <input type="text" onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="mobile" class="validate" v-model="profile.mobile">
            </div>
            <div class="input-field col s12 m4">
              <label for="fax">{{ $t('auth.fax') }}</label>
              <input type="text" onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="fax" class="validate" v-model="profile.fax">
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s4 m2">
              <label for="postal-code">{{ $t('auth.postal') }}</label>
              <input type="text" id="postal-code" class="validate" v-model="profile.postal" required>
            </div>
            <div class="input-field col s8 m4">
              <label for="location">{{ $t('auth.physical_address') }}</label>
              <input type="text" id="location" class="validate" v-model="profile.location" required>
            </div>
            <div class="input-field col s9 m4">
              <label for="street">{{ $t('auth.street') }}</label>
              <input type="text" id="street" class="validate" v-model="profile.street" required>
            </div>
            <div class="input-field col s3 m2">
              <label for="hous-number">{{ $t('auth.number') }}</label>
              <input type="text" id="hous-number" class="validate" v-model="profile.house_number" required>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 l6">
              <input type="password" id="password" class="validate" minlength="9" v-model="password" required>
              <label for="password">{{ $t('auth.password') }}</label>
            </div>
            <div class="input-field col s12 l6">
              <input type="password" id="password_confirmation" class="validate" minlength="9"
                     v-model="password_confirmation" required>
              <label :class="{'active':profile.password_confirmation}" for="password_confirmation">{{ $t('auth.confirm_password') }}</label>
            </div>
          </div>
          <div id="ctrl-register" class="center">
            <button type="submit" class="btn orange lighten-2 grey-text text-darken-4 z-depth-0">{{ $t('auth.register') }}&nbsp;<i class="material-icons" style="vertical-align: -5px;">send</i></button>
            <span class="transparent-text">&nbsp;&nbsp;</span>
            <a class="btn orange lighten-2 white-text z-depth-0"
               @click="cancelCollapseRegister">
              <i class="material-icons" style="vertical-align: -5px;">cancel</i>&nbsp;{{ $t('auth.cancel') }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint no-undef: 0 */

import userChatMixin from '@/mixins/userChatMixin'

export default {
  name: 'Register',
  data() {
    return {
      name: null,
      surname: null,
      username: null,
      email: null,
      password: null,
      password_confirmation: null,
      profile: {
        gender: 'male',
        user_id: null,
        category: 'customer',
        organization: null,
        phone: null,
        mobile: null,
        fax: null,
        country: 'unused',
        postal: null,
        location: null,
        street: null,
        house_number: null
      },
      error: ''
    }
  },
  mounted () {
    $('.tooltipped').tooltip()
  },
  computed: {
    allRooms() {
      return this.$store.getters['chat/getAllRooms']
    }
  },
  methods: {
    cancelCollapseRegister () {
      this.$emit('close-collapse-register')
    },
    async setUserProfile () {
      console.log('Register: SET USER Profile 2')
      await this.$store.dispatch('auth/setProfileData', this.profile).then((response) => {
        console.log('Register: SetProfile 3' + response.data)
        this.getUserProfile()
      }).catch((error) => {
        console.log('Register: set Profile Error');
        console.log(error)
      })
    },

    initStateProfile(){
      this.$store.dispatch('auth/initStateProfile', this.profile)
    },

    async loginUser () {
      const logUser = new URLSearchParams()
      logUser.append('login', this.email)
      logUser.append('password', this.password)
      await this.$store.dispatch('auth/loginUser', logUser).then((response) => {
        this.profile.user_id = response.data.user.id
        console.log('Register user_id 1: ' + this.profile.user_id)
        this.$emit('close-collapse-register')
        this.setUserProfile()
        this.error = ''
        this.chat.username = response.data.user.username
        this.getSetUserChatData()  // in userChatMixin
      }, error => {
        this.error = error.response.data.error
      })
      scroll(0, 0)
    },
    // put profile data in vuex store
    async getUserProfile () {
      await this.$store.dispatch('auth/getUserProfile', this.profile.user_id).then(() => {
        console.log('Register: getUserProfile 4: ' + this.profile.user_id)
        // reload is required to set registered user beside avatar if the avatar is used
        document.location.reload()
      })
    },

    async registerUser () {
      const user = new URLSearchParams()

      user.append('name', this.name)
      user.append('surname', this.surname)
      user.append('username', this.name + ' ' + this.surname)
      user.append('email', this.email)
      user.append('password', this.password)
      user.append('password_confirmation', this.password_confirmation)

      await this.$store.dispatch('auth/registerUser', user).then(() => {
        this.loginUser()
        M.toast({ html: this.$i18n.t('footer.registered_success'), classes: 'rounded orange-text' })
      }).catch(() => {
        console.log('User cannot be registered')
      })
    }
  },
  mixins: [userChatMixin]
}
</script>

<style scoped>

</style>
