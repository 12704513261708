<template>
  <!-- route to chart video room -->
  <router-link :to="`/${$i18n.locale}/about`"
     class="tooltipped"
     data-position="bottom" :data-tooltip="$t('nav.about_us')"
     data-target="mobile-links"
  >
    <i class="material-icons orange-text text-lighten-2">info</i>
  </router-link>

</template>

<script>
export default {
  name: 'AboutNavigation'
}
</script>

<style scoped>

</style>
