import axios from 'axios'
// const apiUrl = process.env.VUE_APP_API_BASE_URL
const apiUrl = process.env.VUE_APP_BASE_URL

export const upload = ({commit}, formData) => {
	return new Promise((resolve, reject) => {
		axios.post(apiUrl + '/jobs/upload.php',
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                commit('STORE_UPLOADED_FILES',response.data)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
		})
	})
}
