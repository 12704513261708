<template>
  <header class="main-header">
    <!-- NAVBAR -->
    <nav id="navigation" class="transparent">
      <div class="container-fluid">
        <!--div class="nav-wrapper light-blue darken-3 z-depth-3"-->
        <div class="nav-wrapper z-depth-2">
          <router-link to="/" class="tooltipped brand-logo orange-text text-lighten-2 z-depth-0 flow-text"
             data-position="bottom" :data-tooltip="$t('nav.home')">
            <span style="margin-left: 2vw; text-shadow: 3px 3px 3px #000; vertical-align: 5px;" class="flow-text hide-on-small-only">Lng-Net</span>
            <span style="text-shadow: 3px 3px 3px #000; vertical-align: 5px;" class="flow-text center hide-on-med-and-up">Lng-Net</span>
          </router-link>
          <!-- remove on production -->
          <span style="margin-left: 200px; font-size: 20px;color: #ff0000; text-shadow: 2px 2px 2px #000" class="hide-on-small-only flow-text">Admin-TESTphase, bitte nicht benutzen!!!</span>
          <!--span style="font-size: 20px;color: #ff0000; text-shadow: 2px 2px 2px #000" class="hide-on-med-and-up flow-text">TestPhase!</span-->

          <!-- to here remove -->
          <a href="#" data-target="mobile-nav" class="sidenav-trigger"><i class="material-icons">menu</i></a>
          <ul class="language-switcher right">
            <LanguageSwitcher></LanguageSwitcher>
          </ul>
          <ul class="right hide-on-small-only">
            <!--li class="language-switcher">
              <LanguageSwitcher></LanguageSwitcher>
            </li-->
            <li class="language-switcher">
              <AboutNavigation></AboutNavigation>
            </li>
            <li class="upload-starter" v-if="authorized">
              <UploadStarter></UploadStarter>
            </li>
            <!--li class="language-switcher" v-if="authorized">
              <ChatStarter></ChatStarter>
            </li-->
            <li class="toggle-fullscreen">
              <ToggleFullscreen></ToggleFullscreen>
            </li>
            <li class="top-down">
              <TopDown></TopDown>
            </li>
          </ul>
          <ul class="sidenav grey darken-4 z-depth-0 hide-on-large-only" id="mobile-nav">
            <li>
              <router-link :to="`/${$i18n.locale}/`" data-target="mobile-links">
              >
                <i class="material-icons orange-text text-lighten-2">home</i>
                <span class="orange-text text-lighten-4">{{ $t('nav.home') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="`/${$i18n.locale}/about`" data-target="mobile-links">
                <i class="material-icons orange-text text-lighten-2">info</i>
                <span class="orange-text text-lighten-4">{{ $t('nav.about_us') }}</span>
              </router-link>
            </li>
            <li class="upload-starter" v-if="authorized">
              <router-link :to="`/${$i18n.locale}/upload`" data-target="mobile-links">
              >
                <i class="material-icons orange-text text-lighten-2">file_upload</i>
                <span class="orange-text text-lighten-4">{{ $t('nav.upload') }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav> <!-- Navigation -->
    <!-- Header below navbar -->
    <div class="mainjob container-fluid">
      <div class="row">
        <div class="col s12 m4 left hide-on-med-and-down" style="margin-left: 2vw; margin-top: 70px;">
          <h1 class="flow-text">Deutsch-Arabisch</h1>
          <h1 class="flow-text">Dolmetschen & Übersetzen</h1>
          <br>
        </div>
        <div class="col s12 m4 left hide-on-large-only" style="margin-left: 2vw; margin-top: 70px;">
          <h1 class="flow-text">Deutsch-Arabisch</h1>
          <h1 class="flow-text"  style="font-size: 18px">Dolmetschen & Übersetzen</h1>
          <br>
        </div>
        <div class="col s12 m4 right" style="margin-top: 70px;">
          <div style="margin-right: 2vw;">
            <h1 class="flow-text right-align">عربي ألماني</h1>
            <h1 class="flow-text right-align">ترجمة تحريرية وشفوية</h1>
          </div>
          <br>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint no-undef: 0 */
import TopDown from './navParts/TopDown'
// import homeMixin from '@/mixins/homeMixin'
import LanguageSwitcher from '@/views/components/navParts/LanguageSwitcher'
import ToggleFullscreen from '@/views/components/navParts/ToggleFullscreen'
// import SideNavigation from '@/views/components/navParts/SideNavigation'
// import ChatStarter from '@/views/components/navParts/ChatStarter'
import UploadStarter from '@/views/components/navParts/UploadStarter'
import AboutNavigation from "@/views/components/navParts/AboutNavigation";


export default {
  data(){
    return {
      //
    }
  },
  components: {
    AboutNavigation,
//     SideNavigation,
    LanguageSwitcher,
    ToggleFullscreen,
//    ChatStarter,
    UploadStarter,
    TopDown
  },

  mounted() {
    // const $ = window.jQuery
    this.$nextTick(() => {
      $('.sidenav').sidenav()
      $('.tooltipped').tooltip()
      // CAROUSEL INIT
      $('.carousel.carousel-slider').carousel({ fullWidth: true });

      $('.modal').modal()
    })
  },
  // props: {
  //   'authorized': {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  computed: {
    authorized() {
      return this.$store.getters['auth/authorized']
    }
  },
  methods: {
      //
  }
}
</script>
<style scoped lang="scss">
  #navigation {
    background: url(../../../src/assets/img/nav.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 100%;
    color: white;
  }

  .mainjob{
    background: url(../../../src/assets/img/header.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    color: white;
  }

.row .col{
  padding: 0;
}
</style>
