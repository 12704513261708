import Chat from '@/components/chat/Index.vue'
import VideoRoom from '@/components/chat/VideoRoom.vue'

export default [
    {
        path: 'chat',
        component: Chat,
        name: 'chat',
        meta: {
            guest: true,
            needsAuth: false
        }
    },
    {
        path: 'chat/:roomSlug',
        component: VideoRoom,
        name: 'video-room',
        meta: {
            guest: false,
            needsAuth: true
        }
    }
]
