export default {
  methods: {
    getSetUserChatData() {
      let all = this.allRooms
      let canActiveRooms = []
      let username = this.username
      let payload = {}
      if (username !== ''){
        all.forEach((room) => {
          if (room.activator === username){
            // console.log(room.attendees)
            canActiveRooms.push(room)
          }
        })
      }

      // console.log('Can Active Rooms: ', canActiveRooms)
      if(canActiveRooms.length > 0) {
        payload = {
          'name': username,
          'participantRole': 'activator',
          'canActiveRooms': canActiveRooms
        }
        this.$store.dispatch('chat/setUserChatData', payload).then(()=>{
          // no DB access, only for committing and set data status for logged in user (activator)
        })
      } else { // for participant, who able to attend the activated room
        let theActiveRoom = this.$store.getters['chat/getActiveRoom']
        if (theActiveRoom !== null){
          let attendees = theActiveRoom.attendees
          attendees.forEach((participant) => {
            if(participant.user === username) {
              payload = {
                'name': username,
                'participantRole': 'attendee',
                'canActiveRooms': [] // attendee can't activate room, must be empty
              }
              this.$store.dispatch('chat/setUserChatData', payload).then(()=>{
                // no DB access, only for committing and set data status for logged in user (attendee)
              })
            }
          })
        } else {
          alert(this.$i18n.t('chat.no_room_active'))
        }
      }
    }
  }
}
