/* eslint no-undef: 0 */
import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_BASE_URL
const appUrl = process.env.VUE_APP_BASE_URL


export const registerUser = ({commit}, user) => {
    return axios.post(apiUrl + '/api/auth/register', user).then(response => {
        commit('READY_TO_CONTINUE', true)
        console.log(response)
    }).catch(error => {
        console.log(error.response)
        return error
    })
}

export const initStateProfile = ({commit}, profile) => {
    commit('INIT_PROFILE', profile)
}

// immediate after registration
export const setProfileData = ({commit}, profile) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/profile', profile).then((response) => {
            console.log('ACTION: profile 2')
            console.log(response.data)
            console.log('Action: profile 3')
            commit('SET_CUSTOMER_NUMBER', 1001)
            resolve(response)
            // commit('SET_CUSTOMER_NUMBER', response.data)
        }).catch((error) => {
            console.log('ACTIONS: Profile Settings Error')
            reject(error)
        })
    })

}

// in Profile plugin routes
export const getUserProfile = ({commit}, uid) => {
    // console.log('Action getUserProfile')
    return new Promise((resolve, reject) => {
        axios.get(apiUrl + '/profile/' + uid).then((response) => {
            // console.log(response)
            const profile = response.data[0]
            commit('SET_PROFILE', profile)
            commit('SET_AUTHORIZED', true)
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const loginUser = ({commit}, user) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/api/auth/login', user).then(response => {
            commit('SET_TOKEN', response.data.token)
            commit('SET_USER', response.data.user)
            // commit('SET_AUTHORIZED', true)
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

// Forgot Password axios.post(apiUrl + '/api/auth/login', data).then(response => {
export const forgotPassword = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/api/auth/forgot-password', data).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

// to update user table  and profile table in DB
export const updateUser = ({commit, state}, payload) => {
    // console.log('Action check ID: ' + payload.id)
    // console.log(payload)
    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + state.token
        }
    }

    commit('SET_ERROR', null)
    return axios.post(apiUrl + '/update-account', payload, authorization).then(response => {
        console.log('..........RESPONSED...............');
        console.log(response.data)
    }).catch(error => {
        commit('SET_ERROR', error.response)
        console.log(error.response)
    })
}

export const logout = ({commit}) => {
    commit('SET_AUTHORIZED', false)
    commit('SET_USER', {})
    commit('SET_TOKEN', '')
    commit('SET_PROFILE', null)
}

export const deleteProfile = ({commit}, profile) => {
    return axios.post(apiUrl + '/delete-profile', profile).then((response) => {
        commit('SET_PROFILE', null)
        console.log(response.data)
    })
}

export const deleteUser = ({commit, state}, user) => {
    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + state.token
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/delete-user', user, authorization).then((response) => {
            resolve(response)
            commit('SET_USER', {})
            // console.log(response.data)
        }).catch(error => {
            reject(error)
            // console.log(error.response)
        })
    })
}
