<template>
  <div id="video-room">
    <div class="container-fluid">
      <blockquote>
        <span>Willkommen</span>
        <span style="margin-left: .2vw">by: <strong class="orange-text"
            style="font-size: 0.9vw;">Lng-Net</strong></span>
      </blockquote>
    </div>
    <div class="row" v-if="meStatus === 'on' && meRole !== 'none'">
      <div class="col">
        <vue-webrtc
            ref="webrtc"
            width="100%"
            :roomId="roomID"
            v-on:joined-room="doAttendeeJoined"
            v-on:left-room="doAttendeeLeft"/>
      </div>
    </div>
    <div>
      <p class="lead blockquote">
        <!-- may be notification -->
      </p>
    </div>
  </div>

</template>

<script>
export default {
  name: 'VideoRoom',
  data: function () {
    return {
      roomID: this.$route.params.roomSlug
    }
  },
  components: {
    //
  },
  computed: {
    meName() {
      return this.$store.getters['chat/getMeUsername']
    },
    meRole() {
      return this.$store.getters['chat/getParticipantRole']
    },
    meStatus() {
      return this.$store.getters['chat/getMeStatus']
    },
    meMuted() {
      return this.$store.getters['chat/getMuted']
    },
    mePaused() {
      return this.$store.getters['chat/getPaused']
    }

  },
  methods: {
    //
  }
}
</script>

<style lang="scss" scoped>

.video-list {
  margin-bottom: 10px;
  background: transparent !important;
}

.video-item {
  width: 50%;
  display: inline-block;
  background: transparent !important;
}

.video-item video {
  width: 100%;
  height: auto;
}

blockquote, .blockquote {
  margin: 1vh 0;
  padding-left: 0.5vw;
  border-left: 10px solid #000;
}

.lead{
  position: relative;
 margin-top: 200px;
}

</style>
