<template>
  <div id="chat">
    <div class="chat-init">
      <div v-if="isActivator">
        <h1 class="chat-title">{{ $t('chat.select_activate') }}</h1>
        <ul>
          <li v-for="room in availableRooms" :key="room.id">
            <p>
              <label>
                <!-- bind pickedRoom to id of room if the room picked -->
                <input class="with-gap" id="room.id" name="group1" type="radio" v-model="pickedRoomId"
                       :value="room.id"/>
                <span class="span-nc">{{ room.name }}</span>
              </label>
            </p>
          </li>
        </ul>
        <div class="center" style="margin-bottom: 10px" v-if="pickedRoomId">
          <a href="#" class="btn orange lighten-2 text-white z-depth-0"
             @click="activateRoom"
          >
            <i class="material-icons" style="vertical-align: -5px;">ac_unit</i> {{ $t('chat.activate') }}
          </a>
        </div>
      </div>
      <div v-if="isAttendee">
        <div class="center" style="margin-bottom: 10px">
          <a href="#" class="btn orange lighten-2 text-white z-depth-0"
             @click="setAttendeeData('on')"
          >
            <i class="material-icons" style="vertical-align: -5px;">chat</i> {{ $t('chat.attend') }}
          </a>
        </div>
      </div>
      <div v-if="isNotParticipant">
        alert(this.$i18n.t('chat.no_room_active'))
      </div>
    </div>
    <div class="fixed-action-btn">
      <a href="#" class="btn-floating orange lighten-2 btn-large tooltipped" data-position="left"
         data-tooltip="Navigation" Style="opacity:0.7">
        <i class="material-icons">navigation</i>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */

export default {
  name: 'ChatInit',
  components: {
    //
  },
  data() {
    return {
      activeRoomId: null,
      roomSlug: null,
      pickedRoomId: null
    }
  },

  mounted() {
    //
  },

  computed: {
    isActivator() {
      return this.$store.getters['chat/getParticipantRole'] === 'activator'
    },
    availableRooms() {
      console.log('Can Active Rooms');
      console.log(this.$store.getters['chat/getCanActiveRooms']);
      return this.$store.getters['chat/getCanActiveRooms']
    },
    isAttendee() {
      return this.$store.getters['chat/getParticipantRole'] === 'attendee'
    },
    isNotParticipant() {
      return this.$store.getters['chat/getParticipantRole'] === 'none'
    },
    user() {
      return this.$store.getters['auth/user']
    },
    activeRoom() {
      return this.$store.getters['chat/getActiveRoom']
    }
  },

  methods: {
    // for activator only
    async activateRoom() {
      await this.$store.dispatch('chat/setActiveRoom', this.pickedRoomId)
          .then((response) => {
            console.log(response.data.name)
            // console.log(response.data.id)
            this.activeRoomId = response.data.id
            this.roomSlug = response.data.slug
            M.toast({html: this.$i18n.t('chat.room_activated'), classes: 'rounded orange-text'})
            this.$router.push({ path: `chat/${this.roomSlug}` })
            // Notification to all allowed attendees, !!! they could visit the room !!! (todo: later)
          })
          .catch(error => {
            console.log(error)
          })
    },
    // for activator only
    async deactivateRoom(rId) {
      await this.$store.dispatch('chat/deactivateRoom', rId)
          .then(() => {
            this.activeRoomId = null
          })
    },

    async SetAttendeeData(status) {
      let data = {
        'id': this.activeRoom.id,
        'user': this.user.username,
        'status': status
      }
      await this.$store.dispatch('chat/setAttendeeData', data)
          .then((response) => {
            console.log(response.data)
            this.roomSlug = response.data.slug
            this.$router.push({ path: `chat/${this.roomSlug}` })
          }).catch(error => {
            console.log(error)
          })
    }
  },
  watch: {
    // for activator only
    pickedRoomId(a){
      // if there is an active room, then deactivate it. clicked (changed) room id
      // !!! in this version "single room" deactivate all rooms on change a room
      console.log('Room ID: ', a)
      if(this.activeRoomId !== null && this.activeRoomId !== a) {
        this.deactivateRoom(this.activeRoomId)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat-init {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 60px;
}

.chat-title {
  font-weight: 600;
  color: #9e9e9e;
}

.join {
  width: 100%;
  max-width: 400px;

}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.input {
  font: inherit;
  outline: none;
  width: 100%;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #ffb74d;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #ffb74d;
}
</style>
