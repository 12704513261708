
export const SET_USER = (state, user) => {
    state.user = user
}

export const SET_TOKEN = (state, token) => {
    state.token = token
}

export const SET_AUTHORIZED = (state, isAuth) => {
    state.authorized = isAuth
}

export const INIT_PROFILE = (state, profile) => {
    state.profile = profile
}

export const SET_PROFILE = (state, profile) => {
    state.profile = profile
}

export const SET_ERROR = (state, content) => {
    state.error = content
}

export const SET_CUSTOMER_NUMBER = (state, number) => {
    console.log('Mutations: ' + number)
    state.customerNumber = number
}

export const READY_TO_CONTINUE = (state, isReady ) => {
    state.isReady = isReady
}


export const SET_HELPER = (state, dummy ) => {
    state.dummy = dummy
}
