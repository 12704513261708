<template>
  <!-- route to chart video room -->
  <a :href="`/${$i18n.locale}/upload`"
     class="tooltipped"
     data-position="bottom" :data-tooltip="$t('nav.upload')"
     data-target="mobile-links"
  >
    <i class="material-icons orange-text text-lighten-2">file_upload</i>
  </a>
</template>

<script>
export default {
  name: 'UploadStarter'
}
</script>

<style scoped>

</style>
