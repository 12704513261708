<template>
  <div class="about" id="about-us">
    <div class="devide">
      <!-- empty for style -->
    </div>
    <section id="about-articles" class="grid">
      <article class="article">
        <div class="article__header center">
          <i class="material-icons medium orange-text text-lighten-2">info</i>
          <h5 class="grey-text text-darken-4">LNG-Net</h5>
        </div>
        <div class="articl__body">
          <ul style="text-align: left;">
            <li class="blockquote-l" v-if="lToR">{{ $t('info.app_for') }}</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.app_process') }}</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.app_translator') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.app_for') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.app_process') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.app_translator') }}</li>
          </ul>
        </div>
        <hr class="hide-on-med-and-up">
      </article>
      <article class="article">
        <div class="article__header center">
          <i class="material-icons medium orange-text text-lighten-2">info_outline</i>
          <h5 class="grey-text text-darken-4">{{ $t('info.about_app') }}</h5>
        </div>
        <div class="article__body">
          <ul>
            <li v-if="lToR"><strong>{{ $t('info.to_translate') }}</strong> ({{ $t('info.after_login') }})</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.auto_mail') }}</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.second_mail') }}</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.ready_mail') }}</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.send_post') }}</li>
            <li v-if="lToR"><span class="red-text">-in der Entwickung-</span> <strong>{{ $t('info.to_video') }}</strong> ({{ $t('info.after_login') }})</li>
            <li class="blockquote-l" v-if="lToR">{{ $t('info.about_video') }}</li>

            <li class="right-align" v-if="!lToR" style="line-height: 1.5em"><strong>{{ $t('info.to_translate') }}</strong> ({{ $t('info.after_login') }})</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.auto_mail') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.second_mail') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.ready_mail') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.send_post') }}</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em"><span class="red-text" style="line-height: 1.5em">-in der Entwickung-</span> <strong>{{ $t('info.to_video') }}</strong> ({{ $t('info.after_login') }})</li>
            <li class="right-align" v-if="!lToR" style="line-height: 1.5em">{{ $t('info.about_video') }}</li>
          </ul>
        </div>
      </article>
    </section>
    <div class="article-help center">
    </div>
    <div class="fixed-action-btn  hide-on-small-and-down">
      <a href="#" class="btn-floating orange lighten-2 btn-large tooltipped" data-position="left"
         :data-tooltip="$t('info.navigation')" Style="opacity:0.7">
        <i class="material-icons">navigation</i>
      </a>
    </div>
  </div>
</template>
<script>
/* eslint no-undef: 0 */
// import Footer from '@/views/components/Footer'

export default {
  name: 'About',
  data() {
    return {
      //
    }
  },
  components: {
    // Footer
  },
  computed: {
    lToR() {
      if(this.$i18n.locale === 'ar') {
        return false
      } else {
        return true
      }
    }
  }
}

</script>

<style scoped>
@media screen and (max-width: 767px) {
  blockquote, .blockquote-l {
    margin: 1vh 0;
    padding-left: 0.5vw;
    border-left: 5px solid #a8a8a8;
  }
  .blockquote-r {
    margin: 1vh 0;
    padding-right: 0.5vw;
    border-left: 5px solid #a8a8a8;
  }
}
@media screen and (min-width: 768px) {
  #about-articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  article {
    flex: 0 1 49%;
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
    box-sizing: border-box;
    transition: flex-basis 0.2s linear;
  }
  blockquote, .blockquote-l {
    margin: 1vh 0;
    padding-left: 0.5vw;
    border-left: 10px solid #D4D3B5;
  }
  .blockquote-r {
    margin: 1vh 0;
    padding-right: 0.5vw;
    border-right: 10px solid #D4D3B5;
  }
}
</style>

