<template>
  <div id="page-footer" class="scrollspy">
    <footer class="page-footer">
      <div class="footer-container" id="footer-container">
        <div class="row">
          <FooterText></FooterText>
        </div>
        <div class="row">
          <div class="col s12 m10">
            <h6 class="center blue-grey-text text-lighten-3">{{ $t('footer.menu_title') }}</h6>
            <ul class="collapsible popout z-depth-0" style="border: none;" data-collapsible="accordion">
              <li class="row">
                <div class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="far fa-handshake"></i>
                  <span>{{ $t('footer.contact') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <contact @close-contact="closeCollapseContact"></contact>
                </div>
              </li>
              <!-- Login and Register  -->
              <li class="row" v-if="!authorized">
                <div id="header-register"  class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="material-icons left">account_circle</i>
                  <span>{{ $t('footer.register') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <RegisterStarter></RegisterStarter>
                  <!--Register @close-collapse-register="closeCollapseRegister"></Register-->
                </div>
              </li>
              <li class="row" v-if="!authorized">
                <div id="header-login"  class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="material-icons left">person</i>
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('footer.register_first')">{{ $t('footer.login') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <LoginStarter></LoginStarter>
                  <!--Login @close-collapse-login="closeCollapseLogin"></Login-->
                </div>
              </li>
              <li class="row" v-if="authorized">
                <div id="header-profile" class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="material-icons left">person</i>
                  <span>{{ $t('footer.edit_profile') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <ProfileStarter></ProfileStarter>
                  <!--Profile @close-collapse-profile="closeCollapseProfile"></Profile-->
                </div>
              </li>
              <li class="row z-depth-0 center-align auth-ctrl"  style="margin-top: 10px;" v-if="authorized">
                <div class="auth-ctrl__item">
                  <Logout @log-out="logoutMe" @close-expanded="closeAllCollapsible"></Logout>
                </div>
              </li>
            </ul>
              <!-- End Login and Register -->
              <!-- Legal Requirement -->
              <!----------------------->
            <ul class="collapsible popout z-depth-0" style="border: none;" data-collapsible="accordion">
              <li class="row">
                <div class="center blue-grey-text text-lighten-3">
                  <h6>{{ $t('footer.legal_requirement')}}</h6>
                </div>
              </li>
              <!-- legal requirements -->
              <!-- Imprint, General Terms and Conditions, Privacy Statement -->
              <li class="row">
                <div class="collapsible-header  grey lighten-3 grey-text text-darken-4">
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('footer.imprint_read')">{{ $t('footer.imprint') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <div id="file-imprint" class="legal-container">
                    <view-pdf :filename="imprintFilename"></view-pdf>
                  </div>
                </div>
              </li>
              <li class="row">
                <div class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('footer.read_download')">{{ $t('footer.term_condition') + ' (AGB)' }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2 page-content">
                  <div id="file-agb" class="legal-container">
                    <view-pdf :filename="agbFilename"></view-pdf>
                  </div>
                  <div class="center" style="margin-top: 20px">
                    <a href="#" class="btn orange lighten-2 text-white z-depth-0"
                       @click="download(agbFilename)"
                    >
                      <i class="fas fa-download"></i> {{ $t('footer.download') }}
                    </a>
                  </div>
                </div>
              </li>
              <li class="row">
                <div class="collapsible-header  grey lighten-3 grey-text text-darken-4">
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('footer.read_download')">{{ $t('footer.privacy') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <!--div id="file-dsgvo" class="legal-container"-->
                  <div id="file-dsgvo" class="legal-container">
                    <view-pdf :filename="dsgvoFilename"></view-pdf>
                  </div>
                  <div class="center" style="margin-top: 20px">
                    <a href="#" class="btn orange lighten-2 text-white z-depth-0"
                       @click="download(dsgvoFilename)"
                    >
                      <i class="fas fa-download"></i> {{ $t('footer.download') }}
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- Social media -->
          <div class="col s12 m2">
            <h6 class="center blue-grey-text text-lighten-3">{{ $t('footer.in_touch') }}</h6>
            <ul class="center hide-on-small-only">
              <li style="margin-bottom: 1vh;"><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Facebook" style="margin-top: 2px"><i class="fab fa-facebook"></i></a></li>
              <li style="margin-bottom: 1vh;"><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Twitter"  style="margin-top: 2px"><i class="fab fa-twitter"></i></a></li>
              <li style="margin-bottom: 1vh;"><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Linked In"  style="margin-top: 2px"><i class="fab fa-linkedin"></i></a></li>
              <li style="margin-bottom: 1vh;"><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Instagram"  style="margin-top: 2px"><i class="fab fa-instagram"></i></a></li>
              <li style="margin-bottom: 1vh;"><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Xing"  style="margin-top: 2px"><i class="fab fa-xing"></i></a></li>
            </ul>
            <div class="hide-on-med-and-up center">
              <a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3 m-1" data-position="top" data-tooltip="Facebook" style="margin-top: 2px"><i class="fab fa-facebook"></i></a>
              &nbsp;
              <a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="top" data-tooltip="Twitter"  style="margin-top: 2px"><i class="fab fa-twitter"></i></a>
              &nbsp;
              <a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="top" data-tooltip="Linked In"  style="margin-top: 2px"><i class="fab fa-linkedin"></i></a>
              &nbsp;
              <a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="top" data-tooltip="Instagram"  style="margin-top: 2px"><i class="fab fa-instagram"></i></a>
              &nbsp;
              <a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="top" data-tooltip="Xing"  style="margin-top: 2px"><i class="fab fa-xing"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright darken-4" style="border-top:1px dotted #fff">
        <div class="container center-align"><a class="white-text" href="mailto:jarrar@vtc-net.de">vtc-Net </a>&copy; 2022</div>
      </div>
    </footer>
  </div>
</template>

<script>
/* eslint-disable */
import FooterText from '@/views/components/fotterParts/FooterText'
import Contact from '@/views/components/fotterParts/Contact'
import Logout from '@/components/auth/Logout'
import ViewPdf from '@/misc/ViewPdf'
import ProfileStarter from "@/views/components/fotterParts/ProfileStarter";
import LoginStarter from "@/views/components/fotterParts/LoginStarter";
import RegisterStarter from "@/views/components/fotterParts/RegisterStarter";

export default {
  name: 'Footer',
  data () {
    return {
      downloadDsgvo: false,
      downloadAgb: false
      }
  },

  components: {
    RegisterStarter,
    LoginStarter,
    ProfileStarter,
    FooterText,
    Contact,
    Logout,
    ViewPdf
  },

  methods: {
    logoutMe() {
      this.$store.dispatch('auth/logout')
      // console.log(this.$route.path)
      // console.log(this.$route.name)
      if(this.$route.name !== 'home'){
        this.$router.push({name: 'home'})
      }
      if (this.$store.getters['chat/getParticipantRole'] === 'activator'){
        // todo: update backend, update frontend
      }
    },
    // closeCollapseRegister(){
    //   console.log('Collapse Registe Close')
    //   $('#header-register').click()
    // },
    // closeCollapseLogin(){
    //   console.log('Collapse Profile Clicked')
    //   $('#header-login').click()
    // },
    // closeCollapseProfile(){
    //   $('#header-profile').click()
    // },
    imprintFile (lang) {
      if (lang === 'en') {
        return 'imprint.pdf'
      }
      return 'impressum.pdf'
    },
    agbFile (lang) {
      if (lang === 'en') {
        return 'generalTerms.pdf'
      }
      return 'agb.pdf'
    },
    dsgvoFile (lang) {
      if (lang === 'en') {
        return 'privacy.pdf'
      }
      return 'dsgvo.pdf'
    },
    download (filename) {
      this.$store.dispatch('home/downloadFile', filename)
    },
    getExpandedSectionIndex () {
      const elems = document.querySelectorAll("ul.collapsible li")
      // console.log(elems);
      for (let i = 0; i < elems.length; i++) {
        if (elems[i].className === "active") {
          return i;
        }
      }
    },
    closeCollapseContact () {
      $('.collapsible').collapsible('close', this.eIndex)
    },
    closeAllCollapsible(){
      const elems = document.querySelectorAll("ul.collapsible li")
      // console.log('All Collapsible length: ' + elems.length)
      for (let i = 0; i < elems.length; i++) {
        $('.collapsible').collapsible('close', i)
      }
    }
  },
  computed: {
    authorized () {
      return this.$store.getters['auth/authorized']
    },
    language () {
      return this.$i18n.locale
    },
    imprintFilename () {
      return this.imprintFile(this.language)
    },
    agbFilename () {
      return this.agbFile(this.language)
    },
    dsgvoFilename () {
      return this.dsgvoFile(this.language)
    },

    // collapsible:  expanded index
    eIndex () {
      return this.getExpandedSectionIndex();
    },
    lToR() {
      if(this.$i18n.locale === 'ar') {
        return false
      } else {
        return true
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
      $('.collapsible').collapsible()
      $('.scrollspy').scrollSpy()
      this.closeAllCollapsible()
    })
  }
}
</script>
<style scoped lang="scss">
  .page-footer{
    background: #333;
  }
  .collapsible-body{
    padding-top: 1rem;
  }
  li.active .change-direction {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .legal-container {
    width: 100%;
    overflow: auto;
    border: solid 3px;
  }
  .auth-ctrl{
    position: relative;
    display: flex;
    &__item{
      flex: 1;
    }
  }
</style>
