<template>
  <section id="login" class="section section-login">
    <div class="row" style="margin-left: 0; margin-right: 0;">
      <div class="card-panel grey lighten-3">
        <h4 class="grey-text text-darken-2">{{ $t('auth.login') }}</h4>
        <form id="login-form" role="form" @submit.prevent="loginUser">
          <div class="input-field">
            <input type="email" id="login-email" class="validate" v-model="email" required/>
            <label for="login-email">Email</label>
          </div>
          <div class="input-field">
            <input type="password" id="login-password" class="validate" v-model="password" required/>
            <label for="login-password" data-error="Please enter your password.">{{ $t('auth.password') }}</label>
          </div>
          <div id="ctrl-login" class="center">
            <button type="submit" class="btn orange lighten-2 grey-text text-darken-4 z-depth-0">{{ $t('auth.login') }}&nbsp;<i class="material-icons" style="vertical-align: -5px;">send</i></button>
            <span class="transparent-text">&nbsp;&nbsp;</span>
            <a class="btn orange lighten-2 white-text z-depth-0"
               @click="cancelCollapseLogin">
              <i class="material-icons" style="vertical-align: -5px;">cancel</i>&nbsp;{{ $t('auth.cancel') }}
            </a>
          </div>
          <forgot @onCloseOverlay="closeOverlay"></forgot>
          <div>
            <a class="transparent right z-depth-0 orange-text modal-trigger"
               data-target="modal-forgot" style="cursor: pointer; font-size: 0.8rem; bottom: 5px;"
               @onCloseOverlay="closeOverlay"
            >
              {{ forgot_password }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint no-undef: 0 */
import Forgot from '@/components/auth/Forgot'
import userChatMixin from '@/mixins/userChatMixin'

export default {
  name: 'Login',
  components: {
    Forgot
  },
  data () {
    return {
      email: null,
      password: null,
      forgot_password: this.$i18n.t('auth.forgot_password'),
      uid: null,
      username: ''
    }
  },
  computed: {
    allRooms() {
      return this.$store.getters['chat/getAllRooms']
    }
  },
  methods: {
    cancelCollapseLogin () {
      this.$emit('close-collapse-login')
    },
    closeOverlay () {
      $('.sidenav-overlay').css('display', 'none')
    },
    async loginUser () {
      const user = new URLSearchParams()
      user.append('login', this.email)
      user.append('password', this.password)

      // this.$store.dispatch('auth/loginUser', user)
      await this.$store.dispatch('auth/loginUser', user).then((response) => {
        this.uid = response.data.user.id
        this.$store.dispatch('auth/getUserProfile', this.uid)
        this.error = ''
        this.username = response.data.user.username
        this.getSetUserChatData()
        M.toast({ html: this.$i18n.t('footer.login_success'), classes: 'rounded orange-text' })
      }, error => {
        this.error = error.response.data.error
        if (this.error === 'invalid_credentials') {
          alert(this.$i18n.t('footer.invalid_credentials'))
        }
        // console.log('Login1: ' + error)
      })
      scroll(0, 0)
    }
  },
  mixins: [userChatMixin]
}
</script>

<style scoped>

</style>
