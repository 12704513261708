export const authorized = (state) => state.authorized

export const user = (state) => state.user

export const profile = (state) => state.profile

export const token = (state) => state.token

export const customerNumber = state => state.customerNumber

