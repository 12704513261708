
import Home from '@/components/home/Home'
import About from '@/components/home/About'

export default [
    {
        path: '',
        component: Home,
        name: 'home',
        meta: {
            guest: true,
            needsAuth: false
        }
    },
    {
        path: 'about',
        component: About,
        name: 'about',
        meta: {
            guest: true,
            needsAuth: false
        }
    }
]
