<template>
  <div>
    <a class="waves-effect btn orange lighten-2 grey-text text-darken-4" @click="logout">
      <i class="material-icons right">directions_walk</i>
      {{ $t('footer.logout') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  methods: {
    logout() {
      this.$emit('log-out')
      this.$emit('close-expanded')
      scroll(0,0)
    }
  }
}
</script>

<style scoped>

</style>
