<template>
<div>
  <Register @close-collapse-register="closeCollapseRegister"></Register>
</div>
</template>

<script>
/* eslint-disable */
import Register from '@/components/auth/Register'
export default {
  name: 'RegisterStarter',
  components: {
    Register
  },
  methods: {
    closeCollapseRegister(){
      // console.log('Collapse Registe Close')
      $('#header-register-ltr').click()
      $('#header-register-rtl').click()
    },
  }
}
</script>

<style scoped>

</style>
