export const getAllRooms = (state) => state.all_rooms

export const getCanActiveRooms = (state) => state.canActiveRooms

export const getActiveRoom = (state) => state.activeRoom

export const getParticipantRole = (state) => state.meRole

export const getMeUsername = (state) => state.username

export const getMeStatus = (state) => state.status
