/* eslint no-undef: 0 */
import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_BASE_URL

export const storeAllRooms = ({ commit }) => {
    axios.get(apiUrl + '/rooms').then((response) => {
        commit('ALL_CHAT_ROOMS', response.data)
    })
}

export const setUserChatData = ({ commit }, payload) => {
    commit('SET_USER_CHAT_DATA', payload)
}

// set active flag in DB-Room table  to true
// store active room in vuex store
export const setActiveRoom = ({ commit }, aid) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/activate-room', {id: aid})
            .then((response) => {
                // console.log(response.data) ... activated room = response.data
                commit('SET_ACTIVE_ROOM', response.data)
                resolve(response)
            })
            .catch(error => {
                reject(error)
                // console.log('Room update failed')
                // console.log(error)
            })
    })
}

// One Room can be activated in this
export const deactivateRoom = ({ commit }, aid) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/deactivate-room', {id: aid})
            .then((response) => {
                // console.log(response.data) ... activated room = response.data
                commit('SET_ACTIVE_ROOM', null)
                resolve(response)
            })
            .catch(error => {
                reject(error)
                // console.log('Room update failed')
                // console.log(error)
            })
    })
}

export const setAttendeeData = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/activate-attendee', payload)
            .then((response) => {
                commit('SET_ME_STATUS', payload.status)
                commit('SET_ME_USERNAME', payload.username)
                commit('SET_ACTIVE_ROOM', response.data)
                resolve(response)
            })
            .catch(error => {
                reject (error)
            })
    })
}






