<template>
  <div>
    <div
        class="dragndrop"
        @dragover.prevent="enter"
        @dragenter.prevent="enter"
        @dragleave.prevent="leave"
        @dragend.prevent="leave"
        @drop.prevent="drop"
        :class="{'dragndrop--dragged': isDraggedOver }"
    >
      <label for="file" class="dragndrop__header"
             :class="{ 'dragndrop__header--compact': files.length >= 1 }">
        <span><strong>{{ $t('upload.drag_files') }}</strong> {{ $t('upload.or_click_to_select') }}</span>
      </label>
      <input type="file"
             name="files[]"
             id="file"
             class="dragndrop__input"
             ref="input"
             @change="select"
             multiple
      >
      <div v-if="files.length">
        <p class="center"><b>Upload-Dateiliste: </b><span>Mit dem Zeichen <i class="material-icons orange-text" style="vertical-align: -5px;">remove_circle_outline</i> können Sie eine Datei von der Liste zu entfernen!</span></p>
      </div>
      <div v-for="(file, index) in files" :key="index" class="file-listing">{{ file.name }} <span class="remove-file" @click="removeFile(index)"><i class="material-icons orange-text" style="vertical-align: -5px;">remove_circle_outline</i></span>
      </div>
    </div>
    <div id="submit-files" class="center" style="margin: 10px" v-if="files.length">
      <button type="button" class="btn orange lighten-2 grey-text text-darken-4 z-depth-0 tooltipped" @click="submitFiles" data-position="top" data-tooltip="Upload"><i
          class="material-icons" style="vertical-align: -5px;">file_upload</i> {{ $t('upload.upload') }}
      </button>
      <div v-if="uploadedFiles.length">
        <br>
        <br>
        <h4>{{ $t('upload.uploaded_files') }}</h4>
        <ul>
          <li v-for="(filename, index) in uploadedFiles" :key="index">
            {{ filename }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'UploadForm',

  data() {
    return {
      files: [],
      uploadedFiles: [],
      isDraggedOver: false
    }
  },
  components: {
    // Uploads
  },
  mounted() {
    // const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },

  methods: {
    enter() {
      this.isDraggedOver = true
    },
    leave() {
      this.isDraggedOver = false
    },
    drop(e) {
      this.leave()
      // console.log(e.dataTransfer.files)
      this.handleFilesUpload(e.dataTransfer.files)
    },
    select(e) {
      console.log('Trusted Select: ' + e.isTrusted)
      // console.log(this.$refs.input.files)
      // console.log(e.target.files)
      this.handleFilesUpload(e.target.files)
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    isFileExists(file){
      let registered = this.files
      for (let i = 0; i < registered.length; i++){
        if(registered[i].name === file.name){
          alert('Diese Datei ist bereits zum Uploaden registriert!')
          return true
        }
      }
      return false
    },
    handleFilesUpload(files) {
      for (let i = 0; i < files.length; i++) {
        if(!this.isFileExists(files[i])){
          this.files.push(files[i]);
        }
      }
    },

    submitFiles() {
      let formData = new FormData();
      let filesName = []
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        filesName[i] = file.name
        formData.append('files[' + i + ']', file);
      }
      console.log('FormDataLOG');
      console.log(formData)
      let payload = {
        'formData': formData,
        'filesName': filesName
      }
      this.$store.dispatch('upload/upload', payload).then((response) => {
        this.uploadedFiles = response.data
        alert(this.uploadedFiles.length + ' ' + this.$i18n.t('upload.files_uploaded'))
      }).catch((error) => {
        // alert(error.response.data)
        console.log(error.response.data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dragndrop {
  --dragdrop-min-height: 200px;
  width: 100%;
  min-height: var(--dragdrop-min-height);
  background: #D4D3B5;
  position: relative;
  border: 3px dashed rgba(0, 0, 0, .2);
  padding: 10px;

  &__input {
    display: none;
  }

  &--dragged {
    border-color: #222;
  }
}

.dragndrop__header {
  display: block;
  font-size: 1.1em;
  color: #333;
  vertical-align: middle;
  text-align: center;
  margin: calc(var(--dragdrop-min-height) / 10) 20px;
}

.dragndrop__header span{
  padding: 5px;
  border: 3px dashed #ffa726;
  border-radius: 3px;
}
.dragndrop__header:hover {
  text-decoration: none;
  color: #555;
  cursor: pointer;
}

.file-listing{
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;

}
span.remove-file {
  cursor: pointer;
  float: right;
}
</style>
