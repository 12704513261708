export const ALL_CHAT_ROOMS = (state, allRooms) => {
    state.all_rooms = allRooms
}

export const SET_USER_CHAT_DATA = (state, payload) => {
    state.username = payload.name
    state.meRole = payload.participantRole
    state.canActiveRooms = payload.canActiveRooms
}

export const SET_ME_STATUS = (state, status) => {
    state.status = status
}

export const SET_ME_USERNAME = (state, username) => {
    state.username = username
}

export const SET_ACTIVE_ROOM = (state, room) => {
    state.activeRoom = room
}

export const RESET_ACTIVE_ROOM = (state) => {
    state.activeRoom = null
}

export const SET_PAUSED = (state, value) => {
    state.user.paused = value
}
